<!-- =========================================================================================
    File Name: VxSidebarItem.vue
    Description: Sidebar item component. Extends vuesax framework's 'vs-sidebar-item' component
    Component Name: VxSidebarItem
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    :class="[{'vs-sidebar-item-active':activeLink}, {'disabled-item pointer-events-none': isDisabled}]"
    class="vs-sidebar--item"
    v-if="canSee"
  >
    <router-link
      v-if="to"
      :to="to"
      :class="[{'router-link-active': isSideNav && isSideNav.length > 0 ? GetActiveNav(to, isSideNav) : activeLink}]"
      :target="target"
      exact
    >
      <vs-icon v-if="!featherIcon" :icon-pack="iconPack" :icon="icon"></vs-icon>
      <feather-icon v-else-if="featherIcon" :icon="icon" :class="{'w-3 h-3': iconSmall}"></feather-icon>
      <slot></slot>
    </router-link>
    <a v-else :target="target" :href="href">
      <vs-icon v-if="!featherIcon" :icon-pack="iconPack" :icon="icon"></vs-icon>
      <feather-icon v-else-if="featherIcon" :icon="icon" :class="{'w-3 h-3': iconSmall}"></feather-icon>
      <slot></slot>
    </a>
  </div>
</template>

<script>
export default {
  name: "VxSidebarItem",
  props: {
    icon: {
      default: "",
      type: String
    },
    iconSmall: {
      default: false,
      type: Boolean
    },
    iconPack: {
      default: "material-icons",
      type: String
    },
    href: {
      default: "#",
      type: String
    },
    to: {
      default: null,
      type: String
    },
    index: {
      default: null,
      type: [String, Number]
    },
    featherIcon: {
      default: false,
      type: Boolean
    },
    target: {
      default: "_self",
      type: String
    },
    isDisabled: {
      default: false,
      type: Boolean
    },
    isSideNav: {
      default: null,
      type: Array
    }
  },
  data() {
    return {
      activeLink: false
    };
  },
  watch: {
    $route() {
      this.CheckIsActive();
    }
  },
  methods: {
    GetActiveNav(to, subSideNavs) {
      // return subSideNavs.indexOf(this.$route.path) !== -1
      //   ? true
      //   : this.$route.path === to;

      return subSideNavs.filter(x => this.$route.path.includes(x)).length > 0
        ? true
        : this.$route.path === to;
    },
    CheckIsActive() {
      if (this.to) {
        if (this.to == this.$router.path && this.to) {
          // console.log("if this.isSideNav", this.isSideNav);

          this.activeLink = true;
        } else this.activeLink = false;
        // if (this.$route.path.slice(1).includes(this.to.slice(1)) && this.to.slice(1)) this.activeLink = true
        // else this.activeLink = false
      }
    }
  },
  computed: {
    canSee() {
      this.$acl.check(this.$store.state.userRole);
      if (this.to) {
        return this.$acl.check(this.$router.match(this.to).meta.rule);
      }
      return true;
    }
  },
  updated() {
    this.CheckIsActive();
  }
};
</script>
