var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"parentx"},[_c('vs-sidebar',{directives:[{name:"hammer",rawName:"v-hammer:swipe.left",value:(_vm.onSwipeLeft),expression:"onSwipeLeft",arg:"swipe",modifiers:{"left":true}}],ref:"mainSidebar",staticClass:"sidebarx main-menu-sidebar items-no-padding",attrs:{"parent":_vm.parent,"hiddenBackground":_vm.clickNotClose,"reduce":_vm.reduce,"default-index":"-1","click-not-close":_vm.clickNotClose,"reduce-not-rebound":_vm.reduceNotRebound},model:{value:(_vm.isSidebarActive),callback:function ($$v) {_vm.isSidebarActive=$$v},expression:"isSidebarActive"}},[_c('div',{on:{"mouseenter":_vm.sidebarMouseEntered,"mouseleave":_vm.sidebarMouseLeave}},[_c('div',{staticClass:"header-sidebar flex items-end justify-between",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"logo flex items-center mx-auto"},[_c('img',{attrs:{"src":_vm.newLogo + '?token=' + _vm.tempToken,"alt":"Logo"}})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showShadowBottom),expression:"showShadowBottom"}],staticClass:"shadow-bottom"}),_c('VuePerfectScrollbar',{ref:"mainSidebarPs",staticClass:"scroll-area--main-sidebar pt-2",attrs:{"settings":_vm.settings},on:{"ps-scroll-y":_vm.psSectionScroll}},[_vm._l((_vm.sidebarItems),function(sidebarItem,index){return [(sidebarItem.header && !_vm.sidebarItemsMin)?_c('span',{key:("header-" + index),staticClass:"navigation-header truncate"},[_vm._v(_vm._s(_vm.$t(sidebarItem.i18n) || sidebarItem.header))]):(!sidebarItem.header)?[(
                !sidebarItem.submenu &&
                  (_vm.userRoleName == 'Super Admin' &&
                  _vm.checkSidebarPerm(
                    sidebarItem.permissions,
                    sidebarItem.contracttype,
                    sidebarItem
                  )
                    ? !sidebarItem.submenu
                    : _vm.moduleAccessIds.includes(sidebarItem.module_id) ||
                      (_vm.userRoleName == 'Admin' &&
                        _vm.checkSidebarPerm(
                          sidebarItem.permissions,
                          sidebarItem.contracttype,
                          sidebarItem
                        ))
                    ? !_vm.moduleAccessIds.includes('1012')
                    : _vm.moduleAccessIds.includes(sidebarItem.module_id))
              )?_c('vx-sidebar-item',{key:("sidebarItem-" + index),ref:"sidebarLink",refInFor:true,attrs:{"index":index,"to":sidebarItem.slug != 'external' ? sidebarItem.url : '',"href":sidebarItem.slug == 'external' ? sidebarItem.url : '',"icon":sidebarItem.icon,"featherIcon":sidebarItem.featherIcon,"target":sidebarItem.target,"isDisabled":sidebarItem.isDisabled,"isSideNav":sidebarItem['subNavs'] ? sidebarItem['subNavs'] : null}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.sidebarItemsMin),expression:"!sidebarItemsMin"}],staticClass:"truncate"},[_vm._v("\n                "+_vm._s(_vm.$t(sidebarItem.i18n) || sidebarItem.name)+"\n              ")]),(sidebarItem.tag && (_vm.isMouseEnter || !_vm.reduce))?_c('vs-chip',{staticClass:"ml-auto",attrs:{"color":sidebarItem.tagColor}},[_vm._v(_vm._s(sidebarItem.tag))]):_vm._e()],1):_vm._e()]:_vm._e()]})],2)],1)]),(!_vm.isSidebarActive)?_c('div',{directives:[{name:"hammer",rawName:"v-hammer:swipe.right",value:(_vm.onSwipeRightSidebarSwipeArea),expression:"onSwipeRightSidebarSwipeArea",arg:"swipe",modifiers:{"right":true}}],staticClass:"sidebar-swipe-area",attrs:{"id":"sidebar-swipe-area"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }